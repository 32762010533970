// Importing images for each category
import battery1 from '../../../assets/products/trade/battery/images (15).jpg';
import battery2 from '../../../assets/products/trade/battery/mc battery.jpg';
import battery3 from '../../../assets/products/trade/battery/ups battery.jpg';
import battery4 from '../../../assets/products/trade/battery/vehicle battery.jpg';

import iron1 from '../../../assets/products/trade/iron/ms iron .jpg';
import iron2 from '../../../assets/products/trade/iron/iron metal sheet.jpg';
import iron3 from '../../../assets/products/trade/iron/pain and iron tins.jpg';

import metals1 from '../../../assets/products/trade/metals/aluminum.jpg';
import metals2 from '../../../assets/products/trade/metals/bc aluminum.jpg';
import metals3 from '../../../assets/products/trade/metals/brass.jpg';

import paper1 from '../../../assets/products/trade/papers/Books_06.jpg';
import paper2 from '../../../assets/products/trade/papers/notebook.jpg';
import paper3 from '../../../assets/products/trade/papers/school books.jpg';

import plastic1 from '../../../assets/products/trade/plastic/black plastic .jpg';
import plastic2 from '../../../assets/products/trade/plastic/pvc plastic.jpg';
import plastic3 from '../../../assets/products/trade/plastic/plastic.jpg';

import unusedproducts1 from '../../../assets/products/trade/unused products/AC 1 ton.jpg';
import unusedproducts2 from '../../../assets/products/trade/unused products/AC 1.5 ton.jpg';
import unusedproducts3 from '../../../assets/products/trade/unused products/AC stabilizer.jpg';
import unusedproducts4 from '../../../assets/products/trade/unused products/comany table fan.jpg';
import unusedproducts5 from '../../../assets/products/trade/unused products/cooler fan.jpg';
import unusedproducts6 from '../../../assets/products/trade/unused products/de frezer small.jpg';
import unusedproducts7 from '../../../assets/products/trade/unused products/hotel big grainder .jpg';
import unusedproducts8 from '../../../assets/products/trade/unused products/images (14).jpeg.jpg';
import unusedproducts9 from '../../../assets/products/trade/unused products/images (16).jpeg.jpg';
import unusedproducts10 from '../../../assets/products/trade/unused products/new model geysar.jpg';
import unusedproducts11 from '../../../assets/products/trade/unused products/old geyser.png';
import unusedproducts12 from '../../../assets/products/trade/unused products/TV stabilizer.jpg';
import unusedproducts13 from '../../../assets/products/trade/unused products/water heater.jpg';
import unusedproducts14 from '../../../assets/products/trade/unused products/washing machine frontload.jpg';

const TradeMiteproducts = {
  battery: {
    kancheepuram: [
      { id: 1, image: battery1, name: 'MC Battery - எம்சிகே பேட்டரி', price: '₹59', unit: 'piece' },
      { id: 2, image: battery2, name: 'Vehicle Battery - வாகன பேட்டரி', price: '₹600', unit: 'piece' },
      { id: 3, image: battery3, name: 'UPS Battery - யூபிஎஸ் பேட்டரி', price: '₹1600', unit: 'piece' },
      { id: 4, image: battery4, name: 'Radiator - ரேடியேட்டர்', price: '₹60', unit: 'piece' },
    ],
    chennai: [
      { id: 1, image: battery1, name: 'MC Battery - எம்சிகே பேட்டரி', price: '₹60', unit: 'piece' },
      { id: 2, image: battery2, name: 'Vehicle Battery - வாகன பேட்டரி', price: '₹601', unit: 'piece' },
      { id: 3, image: battery3, name: 'UPS Battery - யூபிஎஸ் பேட்டரி', price: '₹1601', unit: 'piece' },
      { id: 4, image: battery4, name: 'Radiator - ரேடியேட்டர்', price: '₹61', unit: 'piece' },
    ],
    vellore: [
      { id: 1, image: battery1, name: 'MC Battery - எம்சிகே பேட்டரி', price: '₹59', unit: 'piece' },
      { id: 2, image: battery2, name: 'Vehicle Battery - வாகன பேட்டரி', price: '₹600', unit: 'piece' },
      { id: 3, image: battery3, name: 'UPS Battery - யூபிஎஸ் பேட்டரி', price: '₹1600', unit: 'piece' },
      { id: 4, image: battery4, name: 'Radiator - ரேடியேட்டர்', price: '₹60', unit: 'piece' },
    ],
    ranipet: [
      { id: 1, image: battery1, name: 'MC Battery - எம்சிகே பேட்டரி', price: '₹59', unit: 'piece' },
      { id: 2, image: battery2, name: 'Vehicle Battery - வாகன பேட்டரி', price: '₹600', unit: 'piece' },
      { id: 3, image: battery3, name: 'UPS Battery - யூபிஎஸ் பேட்டரி', price: '₹1600', unit: 'piece' },
      { id: 4, image: battery4, name: 'Radiator - ரேடியேட்டர்', price: '₹60', unit: 'piece' },
    ],
  },
  
  iron: {
    kancheepuram: [
      { id: 11, image: iron1, name: 'Ms Iron - லேசான இரும்பு', price: '₹25', unit: 'kg' },
      { id: 12, image: iron2, name: 'Iron Metal Sheet - இரும்பு உலோக தாள்', price: '₹17', unit: 'kg' },
      { id: 13, image: iron3, name: 'Paint and Iron Tins - பெயிண்ட் மற்றும் இரும்பு டின்கள்', price: '₹13', unit: 'kg' },
    ],
    chennai: [
      { id: 11, image: iron1, name: 'Ms Iron - லேசான இரும்பு', price: '₹26', unit: 'kg' },
      { id: 12, image: iron2, name: 'Iron Metal Sheet - இரும்பு உலோக தாள்', price: '₹18', unit: 'kg' },
      { id: 13, image: iron3, name: 'Paint and Iron Tins - பெயிண்ட் மற்றும் இரும்பு டின்கள்', price: '₹14', unit: 'kg' },
    ],
    vellore: [
      { id: 11, image: iron1, name: 'Ms Iron - லேசான இரும்பு', price: '₹25', unit: 'kg' },
      { id: 12, image: iron2, name: 'Iron Metal Sheet - இரும்பு உலோக தாள்', price: '₹17', unit: 'kg' },
      { id: 13, image: iron3, name: 'Paint and Iron Tins - பெயிண்ட் மற்றும் இரும்பு டின்கள்', price: '₹13', unit: 'kg' },
    ],
    ranipet: [
      { id: 11, image: iron1, name: 'Ms Iron - லேசான இரும்பு', price: '₹25', unit: 'kg' },
      { id: 12, image: iron2, name: 'Iron Metal Sheet - இரும்பு உலோக தாள்', price: '₹17', unit: 'kg' },
      { id: 13, image: iron3, name: 'Paint and Iron Tins - பெயிண்ட் மற்றும் இரும்பு டின்கள்', price: '₹13', unit: 'kg' },
    ],
  },
  
  metals: {
    kancheepuram: [
      { id: 21, image: metals1, name: 'Aluminium - அலுமினியம்', price: '₹119', unit: 'kg' },
      { id: 22, image: metals2, name: 'BC Aluminium - பிசி அலுமினியம்', price: '₹99', unit: 'kg' },
      { id: 23, image: metals3, name: 'Brass - பித்தளை', price: '₹379', unit: 'kg' },
    ],
    chennai: [
      { id: 21, image: metals1, name: 'Aluminium - அலுமினியம்', price: '₹120', unit: 'kg' },
      { id: 22, image: metals2, name: 'BC Aluminium - பிசி அலுமினியம்', price: '₹100', unit: 'kg' },
      { id: 23, image: metals3, name: 'Brass - பித்தளை', price: '₹380', unit: 'kg' },
    ],
    vellore: [
      { id: 21, image: metals1, name: 'Aluminium - அலுமினியம்', price: '₹119', unit: 'kg' },
      { id: 22, image: metals2, name: 'BC Aluminium - பிசி அலுமினியம்', price: '₹99', unit: 'kg' },
      { id: 23, image: metals3, name: 'Brass - பித்தளை', price: '₹379', unit: 'kg' },
    ],
    ranipet: [
      { id: 21, image: metals1, name: 'Aluminium - அலுமினியம்', price: '₹119', unit: 'kg' },
      { id: 22, image: metals2, name: 'BC Aluminium - பிசி அலுமினியம்', price: '₹99', unit: 'kg' },
      { id: 23, image: metals3, name: 'Brass - பித்தளை', price: '₹379', unit: 'kg' },
    ],
  },
  
  paper: {
    kancheepuram: [
      { id: 31, image: paper1, name: 'Tamil & English Novels - தமிழ் & ஆங்கில நாவல்கள்', price: '₹5', unit: 'piece' },
      { id: 32, image: paper2, name: 'Note Book - நோட்டு புத்தகம்', price: '₹13', unit: 'piece' },
      { id: 33, image: paper3, name: 'School Books - பள்ளி புத்தகங்கள்', price: '₹8', unit: 'piece' },
    ],
    chennai: [
      { id: 31, image: paper1, name: 'Tamil & English Novels - தமிழ் & ஆங்கில நாவல்கள்', price: '₹6', unit: 'piece' },
      { id: 32, image: paper2, name: 'Note Book - நோட்டு புத்தகம்', price: '₹14', unit: 'piece' },
      { id: 33, image: paper3, name: 'School Books - பள்ளி புத்தகங்கள்', price: '₹9', unit: 'piece' },
    ],
    vellore: [
      { id: 31, image: paper1, name: 'Tamil & English Novels - தமிழ் & ஆங்கில நாவல்கள்', price: '₹5', unit: 'piece' },
      { id: 32, image: paper2, name: 'Note Book - நோட்டு புத்தகம்', price: '₹13', unit: 'piece' },
      { id: 33, image: paper3, name: 'School Books - பள்ளி புத்தகங்கள்', price: '₹8', unit: 'piece' },
    ],
    ranipet: [
      { id: 31, image: paper1, name: 'Tamil & English Novels - தமிழ் & ஆங்கில நாவல்கள்', price: '₹5', unit: 'piece' },
      { id: 32, image: paper2, name: 'Note Book - நோட்டு புத்தகம்', price: '₹13', unit: 'piece' },
      { id: 33, image: paper3, name: 'School Books - பள்ளி புத்தகங்கள்', price: '₹8', unit: 'piece' },
    ],
  },
  
  plastic: {
    kancheepuram: [
      { id: 41, image: plastic1, name: 'Black Plastic - கறுப்பு பிளாஸ்டிக்', price: '₹3', unit: 'kg' },
      { id: 42, image: plastic2, name: 'PVC Plastic - பிவிசி பிளாஸ்டிக்', price: '₹14', unit: 'kg' },
      { id: 43, image: plastic3, name: 'Plastic - நெகிழி', price: '₹14', unit: 'kg' },
    ],
    chennai: [
      { id: 41, image: plastic1, name: 'Black Plastic - கறுப்பு பிளாஸ்டிக்', price: '₹4', unit: 'kg' },
      { id: 42, image: plastic2, name: 'PVC Plastic - பிவிசி பிளாஸ்டிக்', price: '₹15', unit: 'kg' },
      { id: 43, image: plastic3, name: 'Plastic - நெகிழி', price: '₹15', unit: 'kg' },
    ],
    vellore: [
      { id: 41, image: plastic1, name: 'Black Plastic - கறுப்பு பிளாஸ்டிக்', price: '₹3', unit: 'kg' },
      { id: 42, image: plastic2, name: 'PVC Plastic - பிவிசி பிளாஸ்டிக்', price: '₹14', unit: 'kg' },
      { id: 43, image: plastic3, name: 'Plastic - நெகிழி', price: '₹14', unit: 'kg' },
    ],
    ranipet: [
      { id: 41, image: plastic1, name: 'Black Plastic - கறுப்பு பிளாஸ்டிக்', price: '₹3', unit: 'kg' },
      { id: 42, image: plastic2, name: 'PVC Plastic - பிவிசி பிளாஸ்டிக்', price: '₹14', unit: 'kg' },
      { id: 43, image: plastic3, name: 'Plastic - நெகிழி', price: '₹14', unit: 'kg' },
    ],
  },

  unusedproducts: {
    kancheepuram: [
      { id: 51, image: unusedproducts1, name: 'AC 1 Ton full set - ஏசி 1 டன்', price: '₹1900', unit: 'piece' },
      { id: 52, image: unusedproducts2, name: 'AC 1.5 Ton fullset - ஏசி 1.5 டன்', price: '₹2500', unit: 'piece' },
      { id: 53, image: unusedproducts3, name: 'AC Stabilizer - ஏசி ஸ்டேபிலைசர்', price: '₹90', unit: 'piece' },
      { id: 54, image: unusedproducts4, name: 'Company Table Fan - நிறுவன டேபிள் விசிறி', price: '₹85', unit: 'piece' },
      { id: 55, image: unusedproducts5, name: 'Cooler Fan - குளிர்சாதன விசிறி', price: '₹200', unit: 'piece' },
      { id: 56, image: unusedproducts6, name: 'Deep Freezer Small - சிறிய டீப் ஃப்ரீசர்', price: '₹800', unit: 'piece' },
      { id: 57, image: unusedproducts7, name: 'Hotel Big Grinder - ஹோட்டல் பெரிய கிரைண்டர்', price: '₹550', unit: 'piece' },
      { id: 58, image: unusedproducts8, name: 'Deep Freezer Big - பெரிய டீப் ஃப்ரீசர்', price: '₹1100', unit: 'piece' },
      { id: 59, image: unusedproducts9, name: 'Water Purifier - நீரின் சுத்திகரிப்பு', price: '₹75', unit: 'piece' },
      { id: 60, image: unusedproducts10, name: 'New Model Geyser - புதிய மாதிரி கீசர்', price: '₹220', unit: 'piece' },
      { id: 61, image: unusedproducts11, name: 'Old Model Geyser - பழைய மாதிரி கீசர்', price: '₹350', unit: 'piece' },
      { id: 62, image: unusedproducts12, name: 'TV Stabilizer - டிவி ஸ்டேபிலைசர்', price: '₹40', unit: 'piece' },
      { id: 63, image: unusedproducts13, name: 'Water Heater - நீரின் அடிக்கான்', price: '₹25', unit: 'piece' },
      { id: 64, image: unusedproducts14, name: 'Washing Machine Front Load - முன் ஏற்றலுக்கு மசினி', price: '₹750', unit: 'piece' },
    ],
    chennai: [
      { id: 51, image: unusedproducts1, name: 'AC 1 Ton full set - ஏசி 1 டன்', price: '₹1901', unit: 'piece' },
      { id: 52, image: unusedproducts2, name: 'AC 1.5 Ton fullset - ஏசி 1.5 டன்', price: '₹2501', unit: 'piece' },
      { id: 53, image: unusedproducts3, name: 'AC Stabilizer - ஏசி ஸ்டேபிலைசர்', price: '₹91', unit: 'piece' },
      { id: 54, image: unusedproducts4, name: 'Company Table Fan - நிறுவன டேபிள் விசிறி', price: '₹86', unit: 'piece' },
      { id: 55, image: unusedproducts5, name: 'Cooler Fan - குளிர்சாதன விசிறி', price: '₹201', unit: 'piece' },
      { id: 56, image: unusedproducts6, name: 'Deep Freezer Small - சிறிய டீப் ஃப்ரீசர்', price: '₹801', unit: 'piece' },
      { id: 57, image: unusedproducts7, name: 'Hotel Big Grinder - ஹோட்டல் பெரிய கிரைண்டர்', price: '₹551', unit: 'piece' },
      { id: 58, image: unusedproducts8, name: 'Deep Freezer Big - பெரிய டீப் ஃப்ரீசர்', price: '₹1101', unit: 'piece' },
      { id: 59, image: unusedproducts9, name: 'Water Purifier - நீரின் சுத்திகரிப்பு', price: '₹76', unit: 'piece' },
      { id: 60, image: unusedproducts10, name: 'New Model Geyser - புதிய மாதிரி கீசர்', price: '₹221', unit: 'piece' },
      { id: 61, image: unusedproducts11, name: 'Old Model Geyser - பழைய மாதிரி கீசர்', price: '₹351', unit: 'piece' },
      { id: 62, image: unusedproducts12, name: 'TV Stabilizer - டிவி ஸ்டேபிலைசர்', price: '₹41', unit: 'piece' },
      { id: 63, image: unusedproducts13, name: 'Water Heater - நீரின் அடிக்கான்', price: '₹26', unit: 'piece' },
      { id: 64, image: unusedproducts14, name: 'Washing Machine Front Load - முன் ஏற்றலுக்கு மசினி', price: '₹751', unit: 'piece' },
    ],
    vellore: [
      { id: 51, image: unusedproducts1, name: 'AC 1 Ton full set - ஏசி 1 டன்', price: '₹1900', unit: 'piece' },
      { id: 52, image: unusedproducts2, name: 'AC 1.5 Ton fullset - ஏசி 1.5 டன்', price: '₹2500', unit: 'piece' },
      { id: 53, image: unusedproducts3, name: 'AC Stabilizer - ஏசி ஸ்டேபிலைசர்', price: '₹90', unit: 'piece' },
      { id: 54, image: unusedproducts4, name: 'Company Table Fan - நிறுவன டேபிள் விசிறி', price: '₹85', unit: 'piece' },
      { id: 55, image: unusedproducts5, name: 'Cooler Fan - குளிர்சாதன விசிறி', price: '₹200', unit: 'piece' },
      { id: 56, image: unusedproducts6, name: 'Deep Freezer Small - சிறிய டீப் ஃப்ரீசர்', price: '₹800', unit: 'piece' },
      { id: 57, image: unusedproducts7, name: 'Hotel Big Grinder - ஹோட்டல் பெரிய கிரைண்டர்', price: '₹550', unit: 'piece' },
      { id: 58, image: unusedproducts8, name: 'Deep Freezer Big - பெரிய டீப் ஃப்ரீசர்', price: '₹1100', unit: 'piece' },
      { id: 59, image: unusedproducts9, name: 'Water Purifier - நீரின் சுத்திகரிப்பு', price: '₹75', unit: 'piece' },
      { id: 60, image: unusedproducts10, name: 'New Model Geyser - புதிய மாதிரி கீசர்', price: '₹220', unit: 'piece' },
      { id: 61, image: unusedproducts11, name: 'Old Model Geyser - பழைய மாதிரி கீசர்', price: '₹350', unit: 'piece' },
      { id: 62, image: unusedproducts12, name: 'TV Stabilizer - டிவி ஸ்டேபிலைசர்', price: '₹40', unit: 'piece' },
      { id: 63, image: unusedproducts13, name: 'Water Heater - நீரின் அடிக்கான்', price: '₹25', unit: 'piece' },
      { id: 64, image: unusedproducts14, name: 'Washing Machine Front Load - முன் ஏற்றலுக்கு மசினி', price: '₹750', unit: 'piece' },
    ],
    ranipet: [
      { id: 51, image: unusedproducts1, name: 'AC 1 Ton full set - ஏசி 1 டன்', price: '₹1900', unit: 'piece' },
      { id: 52, image: unusedproducts2, name: 'AC 1.5 Ton fullset - ஏசி 1.5 டன்', price: '₹2500', unit: 'piece' },
      { id: 53, image: unusedproducts3, name: 'AC Stabilizer - ஏசி ஸ்டேபிலைசர்', price: '₹90', unit: 'piece' },
      { id: 54, image: unusedproducts4, name: 'Company Table Fan - நிறுவன டேபிள் விசிறி', price: '₹85', unit: 'piece' },
      { id: 55, image: unusedproducts5, name: 'Cooler Fan - குளிர்சாதன விசிறி', price: '₹200', unit: 'piece' },
      { id: 56, image: unusedproducts6, name: 'Deep Freezer Small - சிறிய டீப் ஃப்ரீசர்', price: '₹800', unit: 'piece' },
      { id: 57, image: unusedproducts7, name: 'Hotel Big Grinder - ஹோட்டல் பெரிய கிரைண்டர்', price: '₹550', unit: 'piece' },
      { id: 58, image: unusedproducts8, name: 'Deep Freezer Big - பெரிய டீப் ஃப்ரீசர்', price: '₹1100', unit: 'piece' },
      { id: 59, image: unusedproducts9, name: 'Water Purifier - நீரின் சுத்திகரிப்பு', price: '₹75', unit: 'piece' },
      { id: 60, image: unusedproducts10, name: 'New Model Geyser - புதிய மாதிரி கீசர்', price: '₹220', unit: 'piece' },
      { id: 61, image: unusedproducts11, name: 'Old Model Geyser - பழைய மாதிரி கீசர்', price: '₹350', unit: 'piece' },
      { id: 62, image: unusedproducts12, name: 'TV Stabilizer - டிவி ஸ்டேபிலைசர்', price: '₹40', unit: 'piece' },
      { id: 63, image: unusedproducts13, name: 'Water Heater - நீரின் அடிக்கான்', price: '₹25', unit: 'piece' },
      { id: 64, image: unusedproducts14, name: 'Washing Machine Front Load - முன் ஏற்றலுக்கு மசினி', price: '₹750', unit: 'piece' },
    ],
  },
};
export default TradeMiteproducts;
